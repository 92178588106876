%border-top {
	border-top: 1px solid $fourth-color;
}

%border-right {
	border-right: 1px solid $fourth-color;
}

%border-bottom {
	border-bottom: 1px solid $fourth-color;
}

%border-left {
	border-left: 1px solid $fourth-color;
}