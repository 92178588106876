input[type="search"] {
	height: 35px;
	padding: 0 10px;
	font-size: 16px;
	border-radius: 0;
	line-height: 35px;
	color: $white-color;
	background-color: $primary-color;
	border: 1px solid $secondary-color;
	
	@include media-breakpoint-up(md){
		height: auto;
		line-height: 1;
		font-size: 11px;
		padding: 5px 10px;
	}
	
	&:focus {
		outline: 0;
		box-shadow: none;
		color: $white-color;
		background-color: $primary-color;
		border: 1px solid $secondary-color;
	}
}

.button,
button {
	font-size: 12px;
	appearance: none;
	padding: 6px 12px;
	border-radius: 4px;
	transition: .3s ease;
	color: $primary-color;
	background-color: $white-color;
	border: 1px solid $lightergray-color;

	&.rounded {
		&-right {
			border-radius: 0 4px 4px 0;
		}
		
		&-left {
			border-radius: 4px 0 0 4px;
			
			+ .rounded-right {
				border-left: 0;
			}
		}
	}

	&:hover {
		color: $primary-color;
		background-color: $lightergray-color;
	}
}

.login {
	&--form {
		display: flex;
		margin-top: 30px;
		flex-direction: column;

		div {
			width: 100%;
		}

		input {
			width: 100%;
			max-width: 220px;

			&[type="text"],
			&[type="password"] {
				height: 35px;
				padding: 0 10px;
				font-size: 16px;
				border-radius: 0;
				line-height: 35px;
				margin-bottom: 10px;
				border: 1px solid $primary-color;

				@include media-breakpoint-up(md){
					height: auto;
					line-height: 1;
					font-size: 11px;
					padding: 5px 10px;
				}

				+ .error {
					margin-top: -10px;
				}

				&:focus {
					outline: 0;
					box-shadow: none;
					border-color: $secondary-color;
				}
			}

			&[type="submit"] {
				border: 0;
				height: 35px;
				font-size: 16px;
				border-radius: 0;
				padding: 0px 15px;
				line-height: 35px;
				color: $white-color;
				transition: .3s ease;
				background-color: $third-color;

				@include media-breakpoint-up(md){
					height: auto;
					line-height: 1;
					font-size: 11px;
					padding: 5px 10px;
				}

				&:hover {
					background-color: darken($third-color, 10%);
				}
			}
		}
	}
}

.error {
    padding: 20px;
    text-align: center;
    color: $white-color;
    background-color: $third-color;
    margin-bottom: 20px;

    &--small {
        @extend .error;
        padding: 0;
        text-align: left;
        color: $third-color;
        margin-bottom: 10px;
        background-color: transparent;
    }
}