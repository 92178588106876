.navbar {
    padding: 0 !important;

    &-nav {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;

        li {
            max-width: 33%;
            flex-basis: 33%;

            .nav-link {
                @extend %border-left;
                display: block;
                font-size: 14px;
                color: $white-color;
                text-transform: lowercase;
                font-family: $headline-font;
                padding: 0px 10px !important;

                &:hover {
                    color: $third-color;
                }

                &.active {
                    cursor: default;
                    color: $third-color;
                }
            }
        }
    }
}

.navigation {
    &--side {
        margin: 0;
        padding: 0;
        list-style: none;

        ul {
            margin: 0;
            list-style: none;
            padding: 0 0 0 15px;

            &.hidden {
                display: none;
            }
        }

        >li {
            @extend %border-bottom;
            border-bottom-color: $secondary-color;

            &:last-child {
                border-bottom: 0;
            }
        }

        li {
            a {
                display: flex;
                padding: 5px 0;
                font-size: 12px;
                color: $fourth-color;

                &:hover {
                    color: $third-color;
                }

                svg {
                    flex-shrink: 0;
                    max-width: 12px;
                    margin-top: 2px;
                    max-height: 12px;
                    margin-right: 10px;
                    fill: $fourth-color;
                }

                &.active {
                    color: $third-color;

                    svg {
                        fill: $third-color;
                    }
                }
            }
        }
    }
}
