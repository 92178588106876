* {
    box-sizing: border-box;
}

body {
    font-size: 11px;
    color: $white-color;
    font-family: $body-font;
    background: $primary-color;
    background: linear-gradient(180deg, rgba(35, 35, 35, 1) 10%, rgba($primary-color, 1) 30%);
}

.wrapper {
    min-height: 100%;
    background-size: initial;
    background-repeat: no-repeat;
    background-position: top center;

    .container {
        max-width: 1000px;
    }
}

.box {
    overflow: auto;
    padding: 15px 10px;
    position: relative;
    color: $fourth-color;
    background-color: $white-color;

    &--end {
        width: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top center;

        &:before {
            content: "";
            display: block;
            padding-top: 7%;
        }
    }

    iframe {
        width: 100%;
        min-height: 250px;
    }

    &.secondary {
        background-color: $secondary-color;
    }
    
    &.third {
        color: $white-color;
        background-color: $third-color;
    }

    & + & {
        margin-top: 15px;
    }
}

table {
    font-size: 11px;
    color: $fourth-color;
    
    td {
        &:first-child {
            padding-right: 25px;
        }
    }
}
