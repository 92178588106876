.footer {
	@extend %border-top;
	padding: 10px 0;
	font-size: 15px;
	margin-top: 45px;

	ul {
		margin: 0;
		padding: 0;
		display: flex;
		list-style: none;

		a {
			@extend %border-left;
			display: block;
			padding: 3px 10px;
			color: $secondary-color;
			text-transform: lowercase;

			&:hover {
				color: $third-color;
			}
		}
	}

	.print {
		@extend %border-right;
		display: flex;
		max-width: 38px;
		padding: 0px 10px;
		align-items: center;
		color: $secondary-color;

		svg {
			height: 18px;
			max-width: 18px;
		}
	}
}