.search {
	&--result {
		display: block;
		padding: 20px 0;
		border-style: solid;
		color: $fourth-color;
		border-width: 1px 0px 0px;
		border-color: $secondary-color;

		svg {
			width: 14px;
			max-height: 14px;
			margin-top: -3px;
			margin-right: 5px;
			fill: $fourth-color;
			transition: $transition;
		}

		h2 {
			transition: $transition;
		}

		&:hover {
			color: $fourth-color;

			h2 {
				color: $third-color;
			}
			
			svg {
				fill: $third-color;
			}
		}
	}

	&--form {
		margin-left: auto;
		margin-right: auto;
		
		@include media-breakpoint-up(md){
			max-width: 220px;
		}
	}
}