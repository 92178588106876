.frontpage {
	&--content {
		position: relative;

		h2, .h2 {
			border-bottom: 1px solid $fourth-color;

			+ p {
				margin-top: 5px;
			}
		}

		&__wrap {
			width: 100%;
			
			@include media-breakpoint-up(md){
				position: relative;
			}

			&:not(:first-child) {
				&:not(:last-child) {
					.frontpage--content__headline {
						border-width: 0 1px;
						border-style: solid;
						border-color: $primary-color;
					}
				}
			}
		}

		&__headline {
			z-index: 2;
			width: 100%;
			font-size: 14px;
			position: relative;
			padding: 25px 10px;
			transition: .6s ease;
			background: rgb(74,74,74);
			background: linear-gradient(180deg, rgba(74,74,74,1) 0%, rgba(51,51,51,1) 100%);
			/* Remove the following 3 lines to make it as current site */
			font-weight: 700;
			text-align: center;
			font-family: $headline-font;
			
			&:hover {
				background: linear-gradient(180deg, rgba(74,74,74,1) 50%, rgba(51,51,51,1) 100%);
				//background: linear-gradient(180deg, rgba(51,51,51,1) 0%, rgba(74,74,74,1) 100%);

				~ .frontpage--content__content {
					opacity: 1;
					bottom: 100%;
					visibility: visible;
				}
			}
		}

		&__content {
			opacity: 0;
			z-index: 1;
			width: 100%;
			padding: 15px;
			font-size: 13px;
			position: absolute;
			visibility: hidden;
			transition: .6s ease;
			bottom: calc(100% - 15px);
			background-color: rgba($black-color, .65);

			&:hover {
				opacity: 1;
				bottom: 100%;
				visibility: visible;
			}
		}
	}
}