// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bazuka styles
@import 'utils/variables';
@import 'utils/placeholders';

@import 'base/base';
@import 'base/typography';

@import 'components/breadcrumbs';
@import 'components/buttons';
@import 'components/footer';
@import 'components/header';
@import 'components/input';
@import 'components/lists';
@import 'components/navigation';
@import 'components/calendar';

@import 'layout/hero';
@import 'layout/search';

@import 'pages/frontpage';

@media print{
	@import 'utils/print';
}