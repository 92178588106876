.premiere {
	&-select {
		border: 0;
		height: 30px;
		font-size: 16px;
		border-radius: 0;
		color: $fourth-color;
		padding: 0px 25px 0 0;
		transition: $transition;
		text-transform: lowercase;
		font-family: $headline-font;
		background-color: transparent;

		&:hover {
			background-color: $not-white-color;
		}

		&:focus {
			outline: none;
		}
	}

	&-table {
		thead {
			tr,
			tr th {
				color: $white-color;
				background-color: $primary-color;
				border-color: #555555 !important;
			}
		}

		tbody {
			tr,
			tr td {
				border-color: $lightgray-color !important;
			}

			tr {
				td {
					padding: 0 !important;

					&:first-child,
					&:last-child {
						padding: 0 !important;
					}

					div {
						display: flex;
						min-height: 97px;
						padding-top: 10px;
						flex-direction: column;
						justify-content: space-between;
					}

					&.another-month {
						opacity: .2;
						filter: grayscale(100%);
						background-color: $lightergray-color;

						label {
							color: $lightergray-color;
							padding: 2px 10px;
							background-color: #777777 !important;
						}
					}

					&.past-day {
						background-color: #EDEEF0;
					}

					&.current-day {
						strong {
							color: $third-color;
						}
					}

					&.has-premiere {
						margin-top: 5px;
						padding-bottom: 0 !important;

						label {
							color: $white-color;
							display: block;
							cursor: default;
							line-height: 1.4;
							padding: 5px 10px;
							word-break: initial;
						}

						small {
							display: block;
							font-size: 10px;
							max-height: 0px;
							overflow: hidden;
							transition: .3s ease;
						}

						input {
							~ span {
								width: 81px;
								display: block;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							&:checked {
								~span {
									width: 100%;
									overflow: initial;
									white-space: normal;
								}

								~ small {
									max-height: 40px;
									transition: .6s ease;
								}
							}
						}
					}

					strong {
						display: block;
						padding: 0 10px;
						font-size: 2rem;
						text-align: right;

						+ label {
							margin-top: 20px;
						}
					}

					label {
						+ label {
							margin-top: 1px;
						}
					}
				}
			}
		}

		thead tr th,
		tbody tr td {
			font-size: 1rem;
			vertical-align: top;
			width: calc(100% / 7);
		}
	}
}

.container {
	&--content {
		.premiere-table {
			thead {
				tr {
					th,
					td {
						font-size: 0.8rem;
					}
				}
			}

			td {
				strong {
					font-size: 1rem;
				}

				&.has-premiere {
					label {
						font-size: 12px;
						margin-bottom: 0;

						span {
							pointer-events: none;
						}
					}
				}
			}
		}

		.premiere {
			&--theater {
				&__color {
					width: 22px;
					height: 17px;
					margin-right: 5px;
				}
				
				&__name {

				}
			}
		}
	}
}