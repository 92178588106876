main {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        padding-left: 15px;

        li {
            position: relative;

            &:before {
				top: 0px;
                left: -13px;
                content: "-";
				position: absolute;
            }
        }

        &.list {
			&--news {
				list-style: none;
				padding: 0;
				
				li {
					padding: 20px 0;
					border-top: 1px solid $secondary-color;

					&:before {
						display: none;
					}

					&:first-child {
						border-top: 0;
					}
				}
			}

            &--sitemap {
				padding: 0;
				list-style: none;

				svg {
					width: 12px;
					margin-right: 5px;
				}

                ul {
					list-style: none;

					li {
						padding: 0;

						a {
							display: block;
							padding: 4px 12px 4px 0px;

							svg {
								transition: $transition;
							}
						}
					}
                }
				
                li {
					&:before {
						display: none;
					}

                    a {
                        color: $primary-color;
						
						svg {
							fill: $primary-color;
						}
						
						&:hover {
							color: $third-color;
							
							svg {
								fill: $third-color;
							}
						}
                    }
                }

                >ul>li {
                    font-weight: 900;

                    a {
                        color: $third-color;
						
						svg {
							fill: $third-color;
						}
						
						&:hover {
							color: $secondary-color;
	
							svg {
								fill: $secondary-color;
							}
						}
                    }
                }
            }
        }

		&.file-group {
			padding-left: 0;

			li {
				margin-top: 10px;

				&:before {
					display: none;
				}

				svg {
					width: 12px;
					margin-top: -1px;
					margin-right: 5px;
					fill: $third-color;
				}
			}
		}

		&.confidential-filelist {
			padding-left: 0;

			li {
				display: flex;
				padding: 1px 0;

				&:before {
					display: none;
				}

				&.appendix {
					margin-left: 15px;
				}

				span {
					display: block;
					flex-shrink: 0;
					padding-right: 5px;
				}
			}
		}
    }

	ol { 
		margin: 0;
		padding: 0;
		counter-reset: counterItem -1;
		list-style-type: none;
		
		li {
			display: block;

			&:before { 
				content: counter(counterItem, decimal-leading-zero) ". "; 
				counter-increment: counterItem;
			}
		}
	}
}
