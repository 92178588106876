.breadcrumb {
    @extend %border-left;
    margin-top: 0px;
    border-radius: 0;
    padding: 0px 10px;
	text-transform: lowercase;
    background-color: transparent;
	
	&-item {
		padding: 3px 0px;

		a {
			color: $secondary-color;
			
			&:hover {
				color: $third-color;
			}
		}
		
		&.active {
			color: $secondary-color;
		}
	}
}