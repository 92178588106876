// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Bazuka Variables
$primary-color:		#434343;
$secondary-color:	#B4B4B4;
$third-color:		#FF42B6;
$fourth-color:		#686868;

$lightgray-color:	#cccccc;
$lightergray-color:	#dddddd;

$not-white-color:	#f4f4f4;
$white-color:		#ffffff;
$black-color:		#000000;

$headline-font:		'AvantRegular', 'FagoOfficeSerifBold', 'Times New Roman', Times, serif;
$body-font:			'Verdana', 'Geneva', 'Arial', 'Helvetica', sans-serif;

$transition:		.3s ease;