%button {
	display: flex;
	font-size: 14px;
	color: $white-color;
	align-items: center;
	transition: $transition;
	text-transform: lowercase;
	background-color: transparent;
	border: 1px solid $white-color;

	svg {
		width: 16px;
		margin-right: 8px;
		fill: $white-color;
	}

	&:hover {
		color: $third-color;
	}
}

.button {
	@extend %button;

	&--search {
		@extend %button;
		border: 0;
		padding: 0;
		font-weight: 400;
		font-family: $headline-font;

		&:hover {
			background-color: transparent;
		}
	}
}