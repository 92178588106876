.hero {
	width: 100%;
	position: relative;
	margin-bottom: 15px;

	&:before {
		content: "";
		display: block;
		padding-top: clamp(200px, 80vh, 315px);
	}

	&--image {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		position: absolute;
		visibility: hidden;
		transition: .5s ease;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		
		&.active {
			opacity: 1;
			visibility: visible;
		}
	}
}