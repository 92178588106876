
@font-face {
	font-family: AvantRegular;
	src: url('../../fonts/Avant-Regular.ttf');
}

@font-face {
	font-family: AvantRegular;
	src: url('../../fonts/Avant-Guard-Bold.ttf');
	font-weight: 700;
}

a {
	color: $third-color;
	transition: $transition;
	
	&:hover {
		text-decoration: none;
		color: darken($third-color, 10%);
	}

	.box.third & {
		color: $white-color;
		
		&:hover {
			color: darken($white-color, 10%);
		}
	}
}

p {
	margin: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-top: 0;
	text-transform: lowercase;
	font-family: $headline-font;

	strong &,
	& strong {
		color: $third-color;
		text-transform: lowercase;
	}
}

.highlight {
	font-size: 16px;
	color: $third-color;
	letter-spacing: -1px;

	.box.third & {
		color: $white-color;
	}
}

h4, .h4 {
	main & {
		font-size: 13px;
		font-weight: 700;
		font-family: $body-font;
		text-transform: capitalize;
	}

	.box & {
		@extend .highlight;
		font-weight: 900;
		margin-bottom: 10px;
		font-family: $body-font;
		text-transform: lowercase;
	}
}

h3, .h3 {
	main & {
		font-size: 16px;
		font-weight: 700;
		color: $third-color;
		letter-spacing: -1px;
	}

	.box.third & {
		@extend .highlight;
		font-weight: 900;
		margin-bottom: -10px;
		font-family: $body-font;
		text-transform: lowercase;
	}
}

h2, .h2 {
	font-size: 18px;
	font-weight: 700;
	color: $fourth-color;
	letter-spacing: -1px;
	text-transform: none;

	p + & {
		margin-top: 25px;
		
		strong {
			margin-top: -25px;
			display: block;
		}
	}

	main & {
		font-size: 20px;

		strong &,
		& strong {
			font-size: 16px;
		}
	}
}

pre.ql-syntax {
    padding: 10px;
	overflow: auto;
    max-width: 680px;
    color: $third-color;
    white-space: normal;
    background-color: $primary-color;
}

hr {
	border-style: solid;
	border-width: 1px 0 0;
	border-color: $fourth-color;

	&.news {
		margin: .7rem 0;
		border-color: $secondary-color;
	}
}

.text-third {
	color: $third-color;
}