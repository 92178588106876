body {
	background: $white-color;
	padding: 0;
}

.wrapper {
	background-image: none !important;
}

.container {
	max-width: 100% !important;
	width: 100% !important;
	
	.row {
		margin-left: 0;
		margin-right: 0;
		width: 100% !important;
		max-width: 100% !important;
	}

	&--header,
	&--breadcrumbs,
	&--sidenavigation,
	&--footer {
		display: none;
	}

	&--content {
		width: 100% !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
}

.box {
	&--end {
		display: none;
	}
}